import React from "react"

const FlexScoreboard = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
    <path d="M0 512v512h1024V0H0v512zm433-284c13 5 22 10 32 20s16 19 20 32l4 10v444l-4 9c-4 13-10 22-20 32a77 77 0 01-32 20l-9 4H166l-10-4c-13-4-22-10-32-20a77 77 0 01-20-32l-3-9V290l3-10a80 80 0 0147-50c15-6 9-6 146-6h127l9 4zm435 1c13 5 22 10 32 20s16 19 20 32l4 10v444l-4 9c-4 13-10 22-20 32a77 77 0 01-32 20l-9 4H601l-10-4c-13-4-22-10-32-20a77 77 0 01-20-32l-4-9 1-222V291l3-10a80 80 0 0147-50c15-6 9-6 146-6h127l9 4z" />
    <path d="M170 256l-12 4c-7 3-19 15-22 22-6 12-6 11-6 118v98h47v-7c0-15 5-40 11-57 12-37 35-67 62-80 18-9 33-13 56-12 43 2 72 20 91 58 11 22 20 63 20 92v7h42v-99c0-107 0-106-6-118-3-7-15-19-22-22-12-6-9-6-137-6-94 0-119 0-124 2z" />
    <path d="M291 391c-17 2-32 15-40 35-6 15-10 37-11 62l-1 11h116v-6c0-18-3-45-7-61-8-30-28-45-57-41zM130 623c0 107 0 106 6 118 3 7 15 19 22 22 12 6 9 6 137 6 127 0 124 0 136-6 7-3 19-15 22-22 6-12 6-11 6-118v-99h-21l-20 1-1 12c-2 41-14 79-32 106-10 14-26 28-40 35-28 14-70 14-100 0-12-5-20-11-29-21-23-24-36-65-39-120v-13h-47v99z" />
    <path d="M239 527c0 15 4 48 7 61 5 23 14 39 26 46 13 8 32 8 47 1 7-3 16-11 20-18 8-15 14-41 16-78v-15H239v3zM605 257l-12 4c-7 3-19 15-22 22-6 12-6 11-6 117v98h56l91 1h35l3-5 8-15c18-36 18-64 1-77-6-5-15-8-28-9-13 0-21 1-31 7-9 4-19 14-25 23l-5 7-20-16a90 90 0 01-19-17c3-9 22-30 36-39 18-11 32-15 54-16 30-1 55 5 76 17 7 5 22 19 26 26 7 13 11 28 11 45 0 18-4 35-15 58l-5 11h80v-98c0-107 0-106-6-118-3-7-15-19-22-22-12-6-9-6-137-6-94 0-119 0-124 2zM565 623c0 108 0 107 6 119 3 7 15 19 22 22 12 6 9 6 137 6 127 0 124 0 136-6 7-3 19-15 22-22 6-12 6-11 6-119v-99h-95l-35 53-35 53 62 1h63v53H634v-18l48-70 48-72H565v99z" />
  </svg>
)

export default FlexScoreboard
